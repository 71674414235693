export const teamScoring = [
  { year: 2000, avgScore: 19.3 },
  { year: 2001, avgScore: 18.7 },
  { year: 2002, avgScore: 21.7 },
  { year: 2003, avgScore: 20.8 },
  { year: 2004, avgScore: 21.5 },
  { year: 2005, avgScore: 20.6 },
  { year: 2006, avgScore: 20.7 },
  { year: 2007, avgScore: 21.7 },
  { year: 2008, avgScore: 21.3 },
  { year: 2009, avgScore: 22.5 },
  { year: 2010, avgScore: 22.0 },
  { year: 2011, avgScore: 22.2 },
  { year: 2012, avgScore: 22.8 },
  { year: 2013, avgScore: 23.4 },
  { year: 2014, avgScore: 22.7 },
  { year: 2015, avgScore: 22.8 },
  { year: 2016, avgScore: 23.1 },
  { year: 2017, avgScore: 22.4 },
  { year: 2018, avgScore: 23.3 },
  { year: 2019, avgScore: 22.8 },
  { year: 2020, avgScore: 24.8 },
  { year: 2021, avgScore: 23.5 },
  { year: 2022, avgScore: 24.3 },
];

export const passTDs = [
  { year: 2000, avgPassTD: 1.28 },
  { year: 2001, avgPassTD: 1.28 },
  { year: 2002, avgPassTD: 1.36 },
  { year: 2003, avgPassTD: 1.28 },
  { year: 2004, avgPassTD: 1.43 },
  { year: 2005, avgPassTD: 1.26 },
  { year: 2006, avgPassTD: 1.27 },
  { year: 2007, avgPassTD: 1.41 },
  { year: 2008, avgPassTD: 1.26 },
  { year: 2009, avgPassTD: 1.39 },
  { year: 2010, avgPassTD: 1.47 },
  { year: 2011, avgPassTD: 1.46 },
  { year: 2012, avgPassTD: 1.48 },
  { year: 2013, avgPassTD: 1.57 },
  { year: 2014, avgPassTD: 1.58 },
  { year: 2015, avgPassTD: 1.64 },
  { year: 2016, avgPassTD: 1.54 },
  { year: 2017, avgPassTD: 1.45 },
  { year: 2018, avgPassTD: 1.65 },
  { year: 2019, avgPassTD: 1.56 },
  { year: 2020, avgPassTD: 1.7 },
  { year: 2021, avgPassTD: 1.54 },
  { year: 2022, avgPassTD: 1.38 },
];

export const rushTDs = [
  { year: 2000, avgRushTD: 0.83 },
  { year: 2001, avgRushTD: 0.74 },
  { year: 2002, avgRushTD: 0.9 },
  { year: 2003, avgRushTD: 0.83 },
  { year: 2004, avgRushTD: 0.81 },
  { year: 2005, avgRushTD: 0.84 },
  { year: 2007, avgRushTD: 0.83 },
  { year: 2008, avgRushTD: 0.93 },
  { year: 2009, avgRushTD: 0.84 },
  { year: 2010, avgRushTD: 0.78 },
  { year: 2011, avgRushTD: 0.78 },
  { year: 2012, avgRushTD: 0.78 },
  { year: 2013, avgRushTD: 0.8 },
  { year: 2014, avgRushTD: 0.74 },
  { year: 2015, avgRushTD: 0.71 },
  { year: 2016, avgRushTD: 0.87 },
  { year: 2017, avgRushTD: 0.74 },
  { year: 2018, avgRushTD: 0.86 },
  { year: 2019, avgRushTD: 0.87 },
  { year: 2020, avgRushTD: 1.04 },
  { year: 2021, avgRushTD: 0.93 },
  { year: 2022, avgRushTD: 0.9 },
];
